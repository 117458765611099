import HeaderComponent from '../../../components/HeaderComponent'
import Navbar from '../../../components/navbar/Navbar'
import StepBack from '../../stepBack'
import Footer from '../../../components/Footer/Footer'
import LittleHeader from './ManyDaysHeader'

import cover from '../../../components/images/inner/kaxeti.webp'

export default function KakhetiInner(){
    return(
        
        <div className="w-full">
            <Navbar homePage='*' />
            
            <div className='pt-[100px]'>
                <HeaderComponent title='Трехдневный Тур' />
            </div>

            <StepBack link='/pages/manyDays/manyDays' />

            
                <img src={cover} alt='cover' className='w-full h-[300px] pl-[120px] xs:pl-[20px] xs:pr-[20px] pr-[120px] object-cover object-center' />
            
                <div className='w-full pl-[120px] xs:pl-[20px] pt-[20px] pr-[120px] xs:pr-[20px] xl:pl-[400px] xl:pr-[400px]' >

                    <br/>
                    <p><b>Цена:</b> 550$ до 5 Человек</p>
                    <br/>

                    <LittleHeader title='День первый'/>
                    <p>
                    &nbsp;&nbsp;Монастырь Старый Шуамта; Новый Шуамта; едем в Дом-Музей Александра Чавчавадзе в Цинандали; Шалаурский Винный Погреб, дегустация высококачественных вин и вкусный обед; приезжаем в город Телави, смотрим достопримечательности; вечером прогуляемся пешком по центру города, по парку или посещаем известного винодела, смотрим его частный винный погреб и этнографический музей. 
                    </p>
                    <br/>

                    <LittleHeader title='День второй'/>
                    <p>
                    направляемся в церковный комплекс с развалинами Академии в Икалто; посещаем частный погреб и маленький музейчик Акидо; едем в церковь Св. Георгия в Атскури с потрясающим видом на Алазанскую долину;  Алаверди монастырь; едем в известную винодельню Братьев-Близнецов в Напареули (у них вкусная кухня, можно пообедать тут же); возвращение в Телави
                    </p>
                    <br/>

                    <LittleHeader title='День третий'/>
                    <p>
                    Едем в бывшую столицу Кахетии Греми, посещаем церковь Архангелов, крепость и музей; направляемся в монастырский комплекс Некреси; посещаем винную компанию Киндзмараули, дегустируем вина; едем в город любви Сигнаги, посещаем Монастырь в Бодбе; возвращаемся в Тбилиси 
                    </p>
                    <br></br>

                    <p><b>В Киндзмараули дегустация 8 лари с чел., в домашней винодельне 15 лари с человека. Внимание! Не всегда успеваем менять цены на билеты в музеи и т.п., особенно после карантина, когда все их массово корректируют, поэтому лучше лишний раз уточнить в переписке. </b></p>

                    <p>
                        <b>В цену включены услуги экскурсовода, водителя и транспортные расходы, дегустация в Кахетии. Летом третий день можно заменить на путешествие по военно-грузинской дороге к Казбег. Цена остается та же. </b>
                    </p>
                </div>
            

            

            <div className='pt-[100px]'>
                <Footer />
            </div>
            
        </div>
    )
}