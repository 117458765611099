import HeaderComponent from '../../components/HeaderComponent'
import Navbar from '../../components/navbar/Navbar'
import StepBack from '../stepBack'
import Footer from '../../components/Footer/Footer'
import LittleHeader from '../manyDays/manyDays_inner/ManyDaysHeader'

import cover from '../../components/images/inner/hikinginner.webp'

export default function HikingInner(){
    return(
        
        <div className="w-full">
            <Navbar homePage='*' />
            
            <div className='pt-[100px]'>
                <HeaderComponent title='ПОХОДЫ В ГОРЫ' />
            </div>

            <StepBack link='/pages/manyDays/manyDays' />

            
                <img src={cover} alt='cover' className='w-full h-[300px] pl-[120px] xs:pl-[20px] xs:pr-[20px] pr-[120px] object-cover object-center' />
            
                <div className='w-full pl-[120px] xs:pl-[20px] pt-[20px] pr-[120px] xs:pr-[20px] xl:pl-[400px] xl:pr-[400px]' >
                    
                    <br/>
                    <p><b>Цена:</b> 1225$ за всю семью, от 1 до 4 Человек</p>
                    <br/>

                    <LittleHeader title='День первый'/>
                    <p>
                    Выезд из Тбилиси. Остановка в Кутаиси. Экскурсия по Кутаиси. Ночь в Кутаиси.
                    </p>
                    <br/>

                    <LittleHeader title='День второй'/>
                    <p>
                    Зугдиди, дворец Дадиани, крупнейшая на Кавказе ГЭС в ущелье реки Ингур(и), сами ущелья реки Ингури, и непосредственно заезд в Сванетию,  село Латали,  где смотрятся три храма Мацхвариши (в честь Спасителя, 11-12 век), Латалис Тарингзел ( в честь Архангелов) 11-16  век., храм пророка Ионы (11  век).  После село Ленджери, сванские башни, храм Архангелов. Закончится первый день в Местиа, где Вас ждет размещение в гостинице, ужин, отдых  или вечерняя прогулка по улицам Местии. 
                    </p>
                    <br/>

                    <LittleHeader title='День третий'/>
                    <p>
                    Отправляемся в село Ушгули  (самый высокий населенный пункт Европы! Его высота  2200 м над уровнем моря!),  по дороге село Кала, где находится самое святое место в Сванетии -   храм святого Петра и святого Квирике («Лагурка»). В самом Ушгули знакомство с летней и зимней резиденциями знаменитой царицы Тамары, храм Ламариа Ушгули. Оттуда откроется завораживающий вид на вершину горы Шхара, самую высокую вершину Грузии.  Далее путь пройдет через  село Чажаши,  Х век., с уникальными фресками. Отдых Возвращение в Местию вечером.
                    </p>
                    <br/>

                    <LittleHeader title='День четвертый'/>
                    <p>
                    Осмотр достопримечательностей Местиа. Лагамис мацховари (9-14 век!),  музей Михаила Хергиани, этнографический дом-музей семьи Маргиани, национальный музей, церковь Святого Георгия,  гора Хацвали, куда поднимемся на канатке, цена подъема 5 лари. Оттуда любуемся на Ушба, двухпиковую гору. Обед. Возможно дополнительное посещение Мартвильского каньона (если позволяет оставшееся время )
                    </p>
                    <br/>

                    <LittleHeader title='День пятый'/>
                    <p>
                    Возврат в Тбилиси. 
                    </p>
                    <br/>
                    <br/>

                
                </div>
            

            

            <div className='pt-[100px]'>
                <Footer />
            </div>
            
        </div>
    )
}