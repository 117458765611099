import HeaderComponent from '../../../components/HeaderComponent'
import Navbar from '../../../components/navbar/Navbar'
import StepBack from '../../stepBack'
import Uslugi from '../../uslugi'
import Footer from '../../../components/Footer/Footer'

import cover from '../../../components/images/inner/mtskheta.webp'
import Options from '../../options'

export default function McxetaInner(){
    return(
        
        <div className="w-full">
            <Navbar homePage='*' />
            
            <div className='pt-[100px]'>
                <HeaderComponent title='Мцхета' />
            </div>

            <StepBack link='/pages/oneDayTours/oneDayTours' />

            
                <img src={cover} alt='cover' className='w-full h-[300px] pl-[120px] xs:pl-[20px] xs:pr-[20px] pr-[120px] object-cover object-center' />
            
                <div className='w-full pl-[120px] xs:pl-[20px] pt-[20px] pr-[120px] xs:pr-[20px] xl:pl-[400px] xl:pr-[400px]'>
                    <Options 
                        time='5'
                        price1='135'
                        price2='160'
                    />

                    <p className='my-[30px]'>
                    &nbsp;&nbsp;Любите дыхание старины, горные виды, хотите иметь возможность глубже познакомиться с историей и культурой Грузии, но при этом далеко не уезжая от Тбилиси? Или Вы в нашей стране второй раз, и стандартная программа по Мцхета (Самтавро-Светицховели-Джвари) Вами уже прожита, хочется чего то нового? Тогда эта экскурсия правильный выбор. Вместе с нашим гидом Вы побываете у истоков не только зарождения христианства в Грузии, но и более ранних времен.
                    </p>

                    <p>
                    Первое место остановки после выезда из Тбилиси (забираем Вас прямо от отеля) <b>крепость Армази, Багинети, Мцхетский акрополь 4-3 века до нашей эры.</b> Отсюда правили цари , здесь царский дворец и бани, марани (место для приготовления и хранения вина) и места поклонения Солнцу.
                    </p><br/>

                    <p>
                    Настроившись на нужный лад. Поринув с головой во времена, когда меч и благородное сердце были главной опорой воину, поднимемся в пещеру Бетлем,где в свое время укрывалась сама Святая Нино, крестительница Грузии (4 век нашей эры). Оттуда получаются очень красивые фото на Мцхету с высоты птичьего полета.
                    </p><br/>

                    <p>
                    Далее побываем <b>на территории женского монастыря Самтавро (4-10 век)</b> , где Святая Нино жила в шалаше и исцеляла людей у куста ежевики. Это же бывшая территория царского сада. Здесь же почивают мощи святого Габриэля Ургебадзе. И опять чудесные виды вокруг. Атмосфера умиротворения и благости.
                    </p><br/>

                    <p>
                    Следующим нас ждет величественный <b>кафедральный собор Светицховели</b> с колодцем святой воды, местами упокоения знаменитых правителей (к примеру основателя Тбилиси Вахтанга Горгасали) животворящим столбом, крепостной стеной , а по дороге к нему сувенирные улочки с возможность продегустировать вино, чачу, специи, чурчхелу, выбрать подарки себе и родным, полюбоваться картинами местных художников и мастеров ручной работы, минанкари (украшения). Все в одном месте, а обрамлении гор и чистого воздуха, колоритных улочек с балкончиками.
                    </p><br/>

                    <p>
                    И после оживленного центра города опять возвращаемся к покою древней мудрости - нас встретят <b>стены Антиокии</b>, где сейчас женский монастрыь, архитектура <b>5 века нашей эры.</b>
                    </p><br/>

                    <p>
                    Следующий пункт остановки зависит от сезона, когда Вы к нам приезжаете. Зимой, поздней осенью или ранней весной дороги туда могут быть недоступны. Все решает погода Вашего дня. Это <b>монастрыь Шио-Мгвиме</b>, основанный одним из тринадцати ассирийских отцов, положивших начало монашеству в Грузии. Он обьединяет сразу несколько комплексов. И , конечно же, тоже находится в красивом природном месте. <b>6 век нашей эры.</b>
                    </p><br/>

                    <p>Далее знаменитый <b>Джвари</b>, 6 века, откуда вид на встречающиеся реки Куру и Арагви.</p>
                    
                </div>
            

            <Uslugi />

            <div className='pt-[100px]'>
                <Footer />
            </div>
            
        </div>
    )
}