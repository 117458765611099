import HeaderComponent from '../../../components/HeaderComponent'
import Navbar from '../../../components/navbar/Navbar'
import StepBack from '../../stepBack'
import Uslugi from '../../uslugi'
import Footer from '../../../components/Footer/Footer'

import cover from '../../../components/images/inner/kazbegi.webp'
import Options from '../../options'

export default function KazbegiOneDay(){
    return(
        
        <div className="w-full">
            <Navbar homePage='*' />
            
            <div className='pt-[100px]'>
                <HeaderComponent title='КАЗБЕГ' />
            </div>

            <StepBack link='/pages/oneDayTours/oneDayTours' />

            
                <img src={cover} alt='cover' className='w-full h-[300px] pl-[120px] xs:pl-[20px] xs:pr-[20px] pr-[120px] object-cover object-center' />
            
                <div className='w-full pl-[120px] xs:pl-[20px] pt-[20px] pr-[120px] xs:pr-[20px] xl:pl-[400px] xl:pr-[400px]'>
                    <Options 
                        time='8-10'
                        price1='200'
                        price2='230'
                    />

                    <p className='my-[30px]'>
                    &nbsp;&nbsp;Дальше могут взобраться только альпинисты! Вас ожидают головокружительные виды, чистый горный воздух и буйство природы. Вы поднимитесь на высоту 2170 м. над уровнем моря! При желании сможете преклониться перед грузинскими святынями (церковь Самеба в Гергети). Сможете купить настоящий горный мед нескольких видов! Попробовать по настоящему вкусные хинкали и форель.
                    </p>
                
                    <li>Выезжаем из Тбилиси. От места вашего жительства.</li><br/>

                    <li>Первая остановка — старинная крепость Ананури (16 столетие)  и прекрасное Жинвальское водохранилище (невероятной красоты бирюзовая гладь среди лесистых гор. Летом можно искупаться!)</li><br/>

                    <li>Покоряем на автомобиле горы и по пути делаем остановки по требованию для фотографирования. А места - обворожительные! Чего только стоит встреча Черной и Белой Арагви или сход горных потоков по минералах, дороги вдоль обрывов и головокружительные виды скал и ущелий</li><br/>

                    <li>Остановка на смотровой площадке перевала Казбег (арка Дружбы). Возможность приобрести сувениры, настоящий горный мед нескольких видов.</li><br/>

                    <li>В Дарьяльском ущелье любуемся километровыми скалами и обителью монаха-отшельника. </li><br/>

                    <li>Проезжаем Гудаури, Степанцминда. По желанию — остановка.</li><br/>

                    <li>Конечный пункт — высокогорная церковь Святой Троицы (Самеба) в Гергети. 2170 м над уровнем моря. С интересной историей и легендами. Почитаемыми святынями, природным источником святой воды. Возможность подняться на верх как на спецтранспорте (дополнительная оплата 25 $ ), так и пешком (два пути — длинный и короткий). Возвращение в Тбилиси.</li><br/>
                    
                    <p><span className='text-[#D7235F] font-[600]'>ВНИМАНИЕ!</span> Есть второй вариант экскурсии, когда Казбег+ Мцхета, стоимость такого маршрута 180 евро (или 200 $)
Обратите также внимание, что эта экскурсия сезонная. Зимой не проводится вовсе из-за большой вероятности застрять в горах (снежные завалы),</p>
                </div>
            

            <Uslugi />

            <div className='pt-[100px]'>
                <Footer />
            </div>
            
        </div>
    )
}