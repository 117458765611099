import review1 from '../../components/images/reviews/reviev1 (1).webp'
import review2 from '../../components/images/reviews/reviev1 (1).webp'
import review3 from '../../components/images/reviews/reviev1 (2).webp'
import review4 from '../../components/images/reviews/reviev1 (2).webp'
import review5 from '../../components/images/reviews/reviev1 (3).webp'
import review6 from '../../components/images/reviews/reviev1 (3).webp'
import review7 from '../../components/images/reviews/reviev1 (4).webp'
import review8 from '../../components/images/reviews/reviev1 (4).webp'
import review9 from '../../components/images/reviews/reviev1 (5).webp'
import review10 from '../../components/images/reviews/reviev1 (5).webp'
import review11 from '../../components/images/reviews/reviev1 (6).webp'
import review12 from '../../components/images/reviews/reviev1 (6).webp'
import review13 from '../../components/images/reviews/reviev1 (7).webp'
import review14 from '../../components/images/reviews/reviev1 (7).webp'
import review15 from '../../components/images/reviews/reviev1 (8).webp'
import review16 from '../../components/images/reviews/reviev1 (8).webp'
import review17 from '../../components/images/reviews/reviev1 (9).webp'
import review18 from '../../components/images/reviews/reviev1 (9).webp'
import review19 from '../../components/images/reviews/reviev1 (10).webp'
import review20 from '../../components/images/reviews/reviev1 (10).webp'
import review21 from '../../components/images/reviews/reviev1 (11).webp'
import review22 from '../../components/images/reviews/reviev1 (11).webp'
import review23 from '../../components/images/reviews/reviev1 (12).webp'
import review24 from '../../components/images/reviews/reviev1 (12).webp'
import review25 from '../../components/images/reviews/reviev1 (13).webp'
import review26 from '../../components/images/reviews/reviev1 (13).webp'
import review27 from '../../components/images/reviews/reviev1 (14).webp'
import review28 from '../../components/images/reviews/reviev1 (14).webp'
import review29 from '../../components/images/reviews/reviev1 (15).webp'
import review30 from '../../components/images/reviews/reviev1 (15).webp'
import review31 from '../../components/images/reviews/reviev1 (16).webp'
import review32 from '../../components/images/reviews/reviev1 (16).webp'
import review33 from '../../components/images/reviews/reviev1 (17).webp'
import review34 from '../../components/images/reviews/reviev1 (18).webp'
import review35 from '../../components/images/reviews/reviev1 (19).webp'
import review36 from '../../components/images/reviews/reviev1 (20).webp'
import review37 from '../../components/images/reviews/reviev1 (21).webp'
import review38 from '../../components/images/reviews/reviev1 (22).webp'
import review39 from '../../components/images/reviews/reviev1 (23).webp'
import review40 from '../../components/images/reviews/reviev1 (24).webp'
import review41 from '../../components/images/reviews/reviev1 (25).webp'
import review42 from '../../components/images/reviews/reviev1 (26).webp'
import review43 from '../../components/images/reviews/reviev1 (27).webp'
import review44 from '../../components/images/reviews/reviev1 (28).webp'
import review45 from '../../components/images/reviews/reviev1 (29).webp'
import review47 from '../../components/images/reviews/reviev1 (30).webp'
import review46 from '../../components/images/reviews/reviev1 (31).webp'
import review48 from '../../components/images/reviews/reviev1 (32).webp'
import review49 from '../../components/images/reviews/reviev1 (33).webp'
import review50 from '../../components/images/reviews/reviev1 (34).webp'
import review51 from '../../components/images/reviews/reviev1 (35).webp'
import review52 from '../../components/images/reviews/reviev1 (36).webp'
import review53 from '../../components/images/reviews/reviev1 (37).webp'
import review54 from '../../components/images/reviews/reviev1 (38).webp'
import review55 from '../../components/images/reviews/reviev1 (39).webp'
import review56 from '../../components/images/reviews/reviev1 (40).webp'
import review57 from '../../components/images/reviews/reviev1 (41).webp'
import review58 from '../../components/images/reviews/reviev1 (42).webp'
import review59 from '../../components/images/reviews/reviev1 (43).webp'
import review60 from '../../components/images/reviews/reviev1 (44).webp'

import review61 from '../../components/images/reviews/reviev1 (45).webp'
import review62 from '../../components/images/reviews/reviev1 (46).webp'
import review63 from '../../components/images/reviews/reviev1 (47).webp'
import review64 from '../../components/images/reviews/reviev1 (48).webp'
import review65 from '../../components/images/reviews/reviev1 (49).webp'
import review66 from '../../components/images/reviews/reviev1 (50).webp'
import review67 from '../../components/images/reviews/reviev1 (51).webp'
import review68 from '../../components/images/reviews/reviev1 (52).webp'
import review69 from '../../components/images/reviews/reviev1 (53).webp'
import review70 from '../../components/images/reviews/reviev1 (54).webp'
import review71 from '../../components/images/reviews/reviev1 (55).webp'
import review72 from '../../components/images/reviews/reviev1 (56).webp'
import review73 from '../../components/images/reviews/reviev1 (57).webp'
import review74 from '../../components/images/reviews/reviev1 (58).webp'
import review75 from '../../components/images/reviews/reviev1 (59).webp'
import review76 from '../../components/images/reviews/reviev1 (60).webp'
import review77 from '../../components/images/reviews/reviev1 (61).webp'
import review78 from '../../components/images/reviews/reviev1 (62).webp'
import review79 from '../../components/images/reviews/reviev1 (63).webp'
import review80 from '../../components/images/reviews/reviev1 (64).webp'
import review81 from '../../components/images/reviews/reviev1 (65).webp'
import review82 from '../../components/images/reviews/reviev1 (66).webp'
import review83 from '../../components/images/reviews/reviev1 (67).webp'
import review84 from '../../components/images/reviews/reviev1 (68).webp'
import review85 from '../../components/images/reviews/reviev1 (69).webp'
import review86 from '../../components/images/reviews/reviev1 (70).webp'
import review87 from '../../components/images/reviews/reviev1 (71).webp'
import review88 from '../../components/images/reviews/reviev1 (72).webp'
import review89 from '../../components/images/reviews/reviev1 (73).webp'
import review90 from '../../components/images/reviews/reviev1 (74).webp'
import review91 from '../../components/images/reviews/reviev1 (75).webp'
import review92 from '../../components/images/reviews/reviev1 (76).webp'
import review93 from '../../components/images/reviews/reviev1 (77).webp'
import review94 from '../../components/images/reviews/reviev1 (78).webp'
import review95 from '../../components/images/reviews/reviev1 (79).webp'
import review96 from '../../components/images/reviews/reviev1 (80).webp'
import review97 from '../../components/images/reviews/reviev1 (81).webp'
import review98 from '../../components/images/reviews/reviev1 (82).webp'
import review99 from '../../components/images/reviews/reviev1 (83).webp'
import review100 from '../../components/images/reviews/reviev1 (84).webp'
import review101 from '../../components/images/reviews/reviev1 (85).webp'
import review102 from '../../components/images/reviews/reviev1 (86).webp'
import review103 from '../../components/images/reviews/reviev1 (87).webp'
import review104 from '../../components/images/reviews/reviev1 (88).webp'
import review105 from '../../components/images/reviews/reviev1 (89).webp'
import review106 from '../../components/images/reviews/reviev1 (90).webp'
import review107 from '../../components/images/reviews/reviev1 (91).webp'
import review108 from '../../components/images/reviews/reviev1 (92).webp'
import review109 from '../../components/images/reviews/reviev1 (93).webp'
import review110 from '../../components/images/reviews/reviev1 (94).webp'
import review111 from '../../components/images/reviews/reviev1 (95).webp'
import review112 from '../../components/images/reviews/reviev1 (96).webp'







export const reviewDB =[
    review1,
    review2,
    review3,
    review4,
    review5,
    review6,
    review7,
    review8,
    review9,
    review10,
    review11,
    review12,
    review13,
    review14,
    review15,
    review16,
    review17,
    review18,
    review19,
    review20,
    review21,
    review22,
    review23,
    review24,
    review25,
    review26,
    review27,
    review28,
    review29,
    review30,
    review31,
    review32,
    review33,
    review34,
    review35,
    review36,
    review37,
    review38,
    review39,
    review40,
    review41,
    review42,
    review43,
    review44,
    review45,
    review46,
    review47,
    review48,
    review49,
    review50,
    review51,
    review52,
    review53,
    review54,
    review55,
    review56,
    review57,
    review58,
    review59,
    review60,
    review61,
    review62,
    review63,
    review64,
    review65,
    review66,
    review67,
    review68,
    review69,
    review70,
    review71,
    review72,
    review73,
    review74,
    review75,
    review76,
    review77,
    review78,
    review79,
    review80,
    review81,
    review82,
    review83,
    review84,
    review85,
    review86,
    review87,
    review88,
    review89,
    review90,
    review91,
    review92,
    review93,
    review94,
    review95,
    review96,
    review97,
    review98,
    review99,
    review100,
    review101,
    review102,
    review103,
    review104,
    review105,
    review106,
    review107,
    review108,
    review109,
    review110,
    review111,
    review112
]

