

export default function Uslugi(){
    return(
        <div className="pl-[120px] xs:pl-[20px] xs:text-[12px] text-[#D7235F] font-[600] pt-[50px] pr-[120px] xs:pr-[20px] xl:pl-[400px] xl:pr-[400px]">
            <p>
                ВЕЗДЕ В СТОИМОСТЬ ВКЛЮЧЕНЫ УСЛУГИ ЭКСКУРСОВОДА, ВОДИТЕЛЯ И ТРАНСПОРТНЫЕ РАСХОДЫ.<br/>
                ЕСТЬ  ЭКОНОМ  ПРЕДЛОЖЕНИЯ, когда гид-водитель в одном лице.<br/>
                ниже цена на услугу водитель+профессиональный гид, как два разных человека.<br/>
                Если Вы хотите отдохнуть бюджетно, напишите нам об этом, можем попробовать составить маршрут под конкретный  бюджет.Индивидуальный подход.
            </p>
        </div>
    )
}