import HeaderComponent from '../../../components/HeaderComponent'
import Navbar from '../../../components/navbar/Navbar'
import StepBack from '../../stepBack'
import Uslugi from '../../uslugi'
import Footer from '../../../components/Footer/Footer'

import cover from '../../../components/images/inner/rabati.webp'
import Options from '../../options'

export default function RabatOneDay(){
    return(
        
        <div className="w-full">
            <Navbar homePage='*' />
            
            <div className='pt-[100px]'>
                <HeaderComponent title='Вардзия и Рабат' />
            </div>

            <StepBack link='/pages/oneDayTours/oneDayTours' />

            
                <img src={cover} alt='cover' className='w-full h-[300px] pl-[120px] xs:pl-[20px] xs:pr-[20px] pr-[120px] object-cover object-center' />
            
                <div className='w-full pl-[120px] xs:pl-[20px] pt-[20px] pr-[120px] xs:pr-[20px] xl:pl-[400px] xl:pr-[400px]'>
                    <Options 
                        time='10'
                        price1='260'
                        price2='285'
                    />

                    <p className='my-[30px]'>
                    &nbsp;&nbsp;Экскурсия для тех, кто хочет ощутить на себе дух времени, интересуется историей Грузии, ценит архитектурное наследие, кого как магнит манит к себе таинство пещер и величие событий, здесь происходящих, не оставит равнодушными людей верующих. Привлекательна и для детей, и для взрослых. <br/>
                    <b>“Кто знает, кто ведает как ждет Вардзия, что ею помнится, какими думами полнится о канувших в вечность веках…”. (Ираклий Абашидзе)</b>
                    </p>

                    <p>Ранний выезд из Тбилиси или Боржоми
Крепость Рабат. Ахалцихе. Красива и величественна. После реконструкции крепость Рабат сзывает к себе туристов со всех концов мира без исключения. Вид на окрестности с ее стен околдует Вас с первых секунд.</p>
                
                    <br/><p>
                    Пещерный город Вардзия, история которого начинается на рубеже XII-XIII веков. Это гигантский монолит из туфа, возлежащий на протяжении почти километра вдоль левого берега Куры. Пещеры, в нем высеченные, уходят вглубь скалы на полкилометра, поднимаются тринадцатью ярусами вверх. Поражающий взгляд и воображение архитектурный комплекс.
До наших дней сохранились большой зал для приемов, жилые помещения для знати и простонародья, хранилище казны, библиотеки-книгохранилища, конюшни, винодельни, пекарни, водоёмы с ключевой водой и купальнями. В центре – главный храм, со старинными фресками, на которых царь Георгий III (при нем началось строительство Вардзии), и дочь его царица Тамар (при ней строительство завершилось).
                    </p><br/>


                    <p>Если выезд из Тбилиси и возврат в Тбилиси. Но экскурсию можно начать и из Боржоми, тогда цена намного дешевле. Пишите - обговорим детали! </p>
                    
                </div>
            

            <Uslugi />

            <div className='pt-[100px]'>
                <Footer />
            </div>
            
        </div>
    )
}